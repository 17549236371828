import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import { CtaLesson } from 'components/cta-lesson'
import { PgWithControls } from 'components/pg-with-controls'
import YouTubeEmbed from 'components/rich-text-content/youtube-embed'
import { PG } from 'constants/pg'
import { urlForImage } from 'lib/sanity'
import Image from 'next/image'
import Link from 'next/link'
import { ReactNode } from 'react'
import { InternalLink } from 'ui-kit/internal-Link'
import { conditionalProp } from 'utils/conditional-prop'
import { relativeUrl } from 'utils/relative-url'

// TODO: move this file to a constants
export const myPortableTextComponents = {
  types: {
    image: ({ value }: any) => <img src={value.imageUrl} />,
    callToAction: ({ value, isInline }: any) =>
      isInline ? <a href={relativeUrl(value.url)}>{value.text}</a> : <div className="callToAction">{value.text}</div>,

    pg: ({ value }: any) => {
      const lessonDuration = value.lessonDuration
      const lessonName = value.lessonName
      return (
        <PgWithControls
          forceNoAuth
          pgName={PG.MAIN_PG}
          defaultRules={{ lessonName, duration: lessonDuration || null }}
        />
      )
    },

    figure: ({
      value,
    }: {
      value: {
        image: SanityImageSource
        alt: string
        title: string
        heightToWidthRatio: number
        href: string
        openInNewTab: boolean
      }
    }) => {
      const width = 808
      const height = Math.floor(width * ((value.heightToWidthRatio || 33.33) / 100))
      const imageElement = (
        <Image
          src={urlForImage(value.image).height(height).width(width).fit('crop').url() || ''}
          width={width}
          height={height}
          alt={value.alt}
          title={value.title}
        />
      )
      // if href is set, render the image inside a Link; otherwise, just render the image
      return (
        <>
          {value.href && (
            <Link href={value.href} target={value.openInNewTab ? '_blank' : '_self'} rel="noopener noreferrer">
              {imageElement}
            </Link>
          )}
          {!value.href && imageElement}
        </>
      )
    },
    ytEmbed: ({ value }: { value: { ytEmbed: string } }) => <YouTubeEmbed embedHtml={value.ytEmbed} />,
  },

  marks: {
    link: ({
      children,
      value,
    }: {
      children: ReactNode
      value?: {
        cta: string
        href: string
        title: string
        blank: boolean
        noFollow: boolean
        sponsored: boolean
        buttonView: boolean
        downloadableFileName: string
      }
    }) => {
      if (!value) return null

      const { cta, title, blank, downloadableFileName, buttonView, noFollow, sponsored } = value

      // render normal link if this is not a CTA
      if (!cta) {
        // Set the rel attribute for the link
        let rel = value?.href.startsWith('/') ? undefined : 'noreferrer noopener'

        // Add "nofollow" to the rel attribute if needed
        if (noFollow) {
          if (rel) {
            rel += ' nofollow'
          } else {
            rel = 'nofollow'
          }
        }

        // Add "sponsored" to the rel attribute if needed
        if (sponsored) {
          if (rel) {
            rel += ' sponsored'
          } else {
            rel = 'sponsored'
          }
        }

        const linkProps = {
          rel,
          href: relativeUrl(value?.href),
          ...conditionalProp(!!title, 'title', title),
          ...conditionalProp(blank, 'target', '_blank'),
          ...conditionalProp(!!downloadableFileName, 'download', downloadableFileName),
        }

        return (
          <>
            {buttonView && (
              <InternalLink {...linkProps} buttonView={{ fluid: true }}>
                {children}
              </InternalLink>
            )}
            {!buttonView && <a {...linkProps}>{children}</a>}
          </>
        )
      }

      return <CtaLesson {...value}>{children}</CtaLesson>
    },
    classNameOrId: (props: {
      children: ReactNode
      value?: {
        className?: string
        id?: string
      }
    }) => {
      const { children, value } = props
      return (
        <span className={value?.className} id={value?.id}>
          {children}
        </span>
      )
    },
  },

  block: {
    // you can customize common block types like below. source: https://www.sanity.io/docs/portable-text-to-react#block
    // h1: (props: any) => <h1>{props.children}</h1>
  },
}
