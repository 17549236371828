import React from 'react'
import styled from 'styled-components'

const YouTubeEmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 2rem 0;
  border-radius: 8px; /* Optional: adds rounded corners */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

interface YouTubeEmbedProps {
  embedHtml: string
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ embedHtml }) => {
  return <YouTubeEmbedContainer dangerouslySetInnerHTML={{ __html: embedHtml }} />
}

export default YouTubeEmbed
