const authorFields = `
  _id,
  name,
  title,
  company,
  image,
  bio,
  email,
  facebook,
  twitter,
  gitHub,
  linkedIn,
`

const postFields = `
  _id,
  title,
  metaDescription,
  body,
  publishedAt,
  heroImage,
  heroImageAltText,
  tags,
  author,
  "slug": slug.current,
  "author": author->{${authorFields}},
`

export const indexQuery = `
  *[_type == "post"] | order(publishedAt desc, _updatedAt desc) {
  ${postFields}
}`

export const postQuery = `
  *[_type == "post" && slug.current == $slug] {
    ${postFields}
  }
`

export const pageQuery = `
  *[_type == "page" && slug.current == $slug] {
    _id,
    title,
    slug,
    metaDescription,
    heroImage,
    body,
  }
`

// [START] Careers

const careersPostFields = `
  _id,
  title,
  metaDescription,
  heroImage,
  body,
  publishedAt,  
  "slug": slug.current,  
`

export const careersIndexQuery = `
  *[_type == "careers"] | order(publishedAt desc, _updatedAt desc) {
  ${careersPostFields}
}`

export const careersPostQuery = `
  *[_type == "careers" && slug.current == $slug] {
    ${careersPostFields}
  }
`
// [END] Careers
